module.exports = `
<!doctype html>
<html>

<head>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-159973482-1"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'UA-159973482-1');
  </script>

</head>

<body>
  <style>
    [data-custom-class='body'],
    [data-custom-class='body'] * {
      background: transparent !important;
    }

    [data-custom-class='title'],
    [data-custom-class='title'] * {
      font-family: Arial !important;
      font-size: 26px !important;
      color: #000000 !important;
    }

    [data-custom-class='subtitle'],
    [data-custom-class='subtitle'] * {
      font-family: Arial !important;
      color: #595959 !important;
      font-size: 14px !important;
    }

    [data-custom-class='heading_1'],
    [data-custom-class='heading_1'] * {
      font-family: Arial !important;
      font-size: 19px !important;
      color: #000000 !important;
    }

    [data-custom-class='heading_2'],
    [data-custom-class='heading_2'] * {
      font-family: Arial !important;
      font-size: 17px !important;
      color: #000000 !important;
    }

    [data-custom-class='body_text'],
    [data-custom-class='body_text'] * {
      color: #595959 !important;
      font-size: 14px !important;
      font-family: Arial !important;
    }

    [data-custom-class='link'],
    [data-custom-class='link'] * {
      color: #3030F1 !important;
      font-size: 14px !important;
      font-family: Arial !important;
      word-break: break-word !important;
    }
  </style>

  <div data-custom-class="body">
    <p style="font-size:15px;"><span style="color: rgb(127, 127, 127);"><strong><span data-custom-class="subtitle">Last
            updated <bdt class="question">February 29, 2020</bdt></span></strong></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">Thank you for
          choosing to be part of our community at <bdt class="question">Wizbord Inc.</bdt>
          <bdt class="block-component"></bdt>, doing business as <bdt class="question">Wizbord</bdt>
          <bdt class="statement-end-if-in-editor"></bdt> (“<bdt class="block-component"></bdt>
          <bdt class="question"><strong>Wizbord</strong></bdt>
          <bdt class="else-block"></bdt>”, “<strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>”). We
          are committed to protecting your personal information and your right to privacy. If you have any questions or
          concerns about our <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span></span>, or our practices with regards to your personal information, please contact us at <bdt
            class="question">info@wizdough.com</bdt>.
        </span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">When you visit
          our <bdt class="block-component"></bdt>website <bdt class="question">wizbord.com</bdt>,<bdt
            class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt> and use our services, you trust us with your personal information. We take
          your privacy very seriously. In this <span style="color: rgb(89, 89, 89);"><span
              data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span></span>, we seek to explain to you in the clearest way possible what information we collect, how we
          use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as
          it is important. If there are any terms in this <span style="color: rgb(89, 89, 89);"><span
              data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span> </span>that you do not agree with, please discontinue use of our <bdt class="block-component"></bdt>
          Sites<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt> and our services.
        </span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">This <span
            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span> </span>applies to all information collected through our <bdt class="block-component"></bdt>
          <bdt class="forloop-component"></bdt>
          <bdt class="question">website</bdt>
          <bdt class="forloop-component"></bdt> (such as <bdt class="question">wizbord.com</bdt>), <bdt
            class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt> and/or any related services, sales, marketing or events (we refer to them
          collectively in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span> </span>as the "<strong>Services</strong>").
        </span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Please
            read this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text">privacy policy</span></span>
                <bdt class="statement-end-if-in-editor"></bdt>
              </span> </span>carefully as it will help you make informed decisions about sharing your personal
            information with us.</span></strong></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
              data-custom-class="heading_1">TABLE OF CONTENTS</span></span></strong></span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infocollect"><span style="color: rgb(89, 89, 89);">1.
          WHAT INFORMATION DO WE COLLECT?</span></a> <span style="color: rgb(89, 89, 89);">
        <bdt class="block-component"></bdt>
      </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infouse"><span style="color: rgb(89, 89, 89);">2. HOW
          DO WE USE YOUR INFORMATION?</span></a><span style="color: rgb(89, 89, 89);"><span
          style="color: rgb(89, 89, 89);">
          <bdt class="statement-end-if-in-editor"></bdt>
        </span></span></p>
    <p style="font-size:15px;"><span style="color: rgb(89, 89, 89);"><a data-custom-class="link" href="#infoshare">3.
          WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
        <bdt class="block-component"><span data-custom-class="body_text"></bdt>
      </span></span><span style="font-size: 15px; color: rgb(89, 89, 89);">
        <bdt class="block-component"></bdt>
      </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#cookies"><span
          style="font-size: 15px; color: rgb(89, 89, 89);">4. DO WE USE COOKIES AND OTHER TRACKING
          TECHNOLOGIES?</span></a><span style="font-size: 15px; color: rgb(89, 89, 89);">
        <bdt class="statement-end-if-in-editor"></bdt>
      </span><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
        </span></span></span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#sociallogins"><span
          style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">5.
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></span></span></a><span style="color: rgb(89, 89, 89);"><span
          style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
            <bdt class="statement-end-if-in-editor"></bdt>
          </span></span>
        <bdt class="block-component"></bdt>
        <bdt class="block-component"></bdt>
      </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#inforetain"><span style="color: rgb(89, 89, 89);">6.
          HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span style="color: rgb(89, 89, 89);"><span
          style="color: rgb(89, 89, 89);">
          <bdt class="block-component"></bdt>
        </span></span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infosafe"><span style="color: rgb(89, 89, 89);">7. HOW
          DO WE KEEP YOUR INFORMATION SAFE?</span></a><span style="color: rgb(89, 89, 89);"><span
          style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
            <bdt class="statement-end-if-in-editor"></bdt>
          </span></span>
        <bdt class="block-component"></bdt>
      </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infominors"><span style="color: rgb(89, 89, 89);">8.
          DO WE COLLECT INFORMATION FROM MINORS?</span></a>
      <bdt class="statement-end-if-in-editor"></bdt>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#privacyrights"><span
          style="color: rgb(89, 89, 89);">9. WHAT ARE YOUR PRIVACY RIGHTS?<bdt class="block-component"></bdt></span></a>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#databreach"><span style="color: rgb(89, 89, 89);">10.
          DATA BREACH</span></a><a data-custom-class="link" href="#privacyrights"><span style="color: rgb(89, 89, 89);">
          <bdt class="statement-end-if-in-editor"></bdt>
        </span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#DNT"><span style="color: rgb(89, 89, 89);">11.
          CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#caresidents"><span style="color: rgb(89, 89, 89);">12.
          DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#policyupdates"><span
          style="color: rgb(89, 89, 89);">13. DO WE MAKE UPDATES TO THIS POLICY?</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#contact"><span style="color: rgb(89, 89, 89);">14. HOW
          CAN YOU CONTACT US ABOUT THIS POLICY?</span></a></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="infocollect" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE
              COLLECT?</span></span></strong><span style="font-size: 19px;"><span data-custom-class="heading_1"><span
              style="color: rgb(89, 89, 89);">
              <bdt class="block-component"></bdt>
            </span></span></span></span></p>
    <div style="line-height: 1.1;"><br></div>
    <div><span style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_2">Personal information you
            disclose to us</span></strong></span></div>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span data-custom-class="body_text">In
              Short:</span></em>  </strong><span data-custom-class="body_text"><em>We collect personal information that
            you provide to us.</em></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"
          style="font-size: 15px;">We collect personal information that you voluntarily provide to us when <bdt
            class="block-component"></bdt> expressing an interest in obtaining information about us or our products and
          services, when participating in activities on the <bdt class="block-component"></bdt>Services<bdt
            class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt> or otherwise contacting us
        </span><span data-custom-class="body_text">.</span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">The personal
          information that we collect depends on the context of your interactions with us and the <bdt
            class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>, the choices you make and the products and features you use. The personal
          information we collect can include the following:
        </span><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>
        </span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
            data-custom-class="body_text">Publicly Available Personal Information.</span></strong><span
          data-custom-class="body_text"> We collect <bdt class="forloop-component"></bdt>
          <bdt class="question">first name, maiden name, last name, and nickname</bdt>; <bdt class="forloop-component">
          </bdt>
          <bdt class="question">email addresses</bdt>; <bdt class="forloop-component"></bdt>
          <bdt class="question">business phone number</bdt>; <bdt class="forloop-component"></bdt>
          <bdt class="question">business email</bdt>; <bdt class="forloop-component"></bdt>
          <bdt class="question">phone numbers</bdt>; <bdt class="forloop-component"></bdt>and other similar data.<span
            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="statement-end-if-in-editor"></bdt>
            </span></span> 
        </span><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>
        </span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
            data-custom-class="body_text">Personal Information Provided by You.</span></strong><span
          data-custom-class="body_text"> We collect <bdt class="forloop-component"></bdt>
          <bdt class="question">data collected from surveys</bdt>; <bdt class="forloop-component"></bdt>and other
          similar data.<span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="statement-end-if-in-editor"></bdt>
            </span></span>
        </span></span><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>
        </span></span></span>
      <bdt class="block-component"><span data-custom-class="body_text"></span></bdt></span>
    </p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Social
            Media Login Data.</span></strong><span data-custom-class="body_text"> We may provide you with the option to
          register using social media account details, like your Facebook, Twitter or other social media account. If you
          choose to register in this way, we will collect the Information described in the section called
          "</span></span><span data-custom-class="body_text"><a data-custom-class="link" href="#sociallogins"><span
            style="color: rgb(89, 89, 89);">HOW DO WE HANDLE YOUR SOCIAL LOGINS</span></a></span><span
        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">" below.</span><span
          style="color: rgb(89, 89, 89);">
          <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt>
        </span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">All personal
          information that you provide to us must be true, complete and accurate, and you must notify us of any changes
          to such personal information.<span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text">
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span></span></span><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>
        </span></span></p>
    <p style="font-size: 15px;"><strong><span data-custom-class="heading_2"><br><span
            style="font-size: 16px;">Information automatically collected</span></span></strong></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short: </span></span> </em> </strong><em><span
            style="font-size: 15px;"><span data-custom-class="body_text">Some information — such as IP address and/or
              browser and device characteristics — is collected automatically when you visit our <bdt
                class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt>.
            </span></span></em></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
            data-custom-class="body_text">We automatically collect certain information when you visit, use or navigate
            the <bdt class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>. This information does not reveal your specific identity (like your name
            or contact information) but may include device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences, referring URLs, device name, country,
            location, information about how and when you use our <bdt class="block-component"></bdt>Services<bdt
              class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt> and other technical information. This information is primarily needed to
            maintain the security and operation of our <bdt class="block-component"></bdt>Services<bdt
              class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>, and for our internal analytics and reporting purposes.<bdt
              class="block-component"></bdt>
          </span></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
            data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar
            technologies. <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
          </span></span></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">
          <bdt class="statement-end-if-in-editor"></bdt>
        </span><span data-custom-class="body_text">
          <bdt class="statement-end-if-in-editor">
            <bdt class="block-component"></bdt>
        </span></span></span></span></span></span></li>
      </ul>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
              data-custom-class="body_text">
              <bdt class="statement-end-if-in-editor">
                <bdt class="block-component"></bdt>
              </bdt>
            </span></span></span></span></bdt></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p id="infouse" style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">2. HOW DO WE USE YOUR
              INFORMATION?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">We process your information for purposes
              based on legitimate business interests, the fulfillment of our contract with you, compliance with our
              legal obligations, and/or your consent.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">We use personal information collected via our <bdt class="block-component">
            </bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt> for a variety of business purposes described below. We process your
            personal information for these purposes in reliance on our legitimate business interests, in order to enter
            into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We
            indicate the specific processing grounds we rely on next to each purpose listed below.
          </span><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></span></p>
    <p style="font-size:15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">We use the information we collect or receive:<bdt class="block-component">
            </bdt>
            <bdt class="block-component"></bdt>
          </span></span></span></span></span><br><br></li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">To send you marketing and promotional communications.</span></strong><span
              data-custom-class="body_text"> We and/or our third party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is in accordance with your marketing
              preferences. You can opt-out of our marketing emails at any time (see the "</span></span></span><span
          data-custom-class="body_text"><a data-custom-class="link" href="#privacyrights"><span
              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">WHAT ARE YOUR PRIVACY
                RIGHTS</span></span></a></span><span style="font-size: 15px;"><span
            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">" below).</span><span
              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                      <bdt class="statement-end-if-in-editor"></bdt>
                      <bdt class="block-component"></bdt>
                    </span></span></span></span></span></span></span><br><br></li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">To send administrative information to you. </span></strong><span
              data-custom-class="body_text">We may use your personal information to send you product, service and new
              feature information and/or information about changes to our terms, conditions, and policies.</span><span
              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                          <bdt class="statement-end-if-in-editor"></bdt>
                          <bdt class="block-component"></bdt>
                        </span></span></span></span></span></span></span></span></span><br><br></li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Fulfill and manage your orders.</span></strong><span
              data-custom-class="body_text"> We may use your information to fulfill and manage your orders, payments,
              returns, and exchanges made through the <bdt class="block-component"></bdt>Services<bdt
                class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt>.
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                              <bdt class="statement-end-if-in-editor"></bdt>
                              <bdt class="block-component"></bdt>
                            </span></span></span></span></span></span></span></span></span></span></span><br><br></li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">To post testimonials.</span></strong><span
              data-custom-class="body_text"> We post testimonials on our <bdt class="block-component"></bdt>Services<bdt
                class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt> that may contain personal information. Prior to posting a testimonial,
              we will obtain your consent to use your name and testimonial. If you wish to update, or delete your
              testimonial, please contact us at <bdt class="block-component"></bdt>
              <bdt class="question">info@wizdough.com</bdt>
              <bdt class="else-block"></bdt> and be sure to include your name, testimonial location, and contact
              information.
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                  <bdt class="statement-end-if-in-editor"></bdt>
                                  <bdt class="block-component"></bdt>
                                </span></span></span></span></span></span></span></span></span></span></span></span></span><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Deliver targeted advertising to you. </span></strong><span
              data-custom-class="body_text">We may use your information to develop and display content and advertising
              (and work with third parties who do so) tailored to your interests and/or location and to measure its
              effectiveness.<bdt class="block-component"></bdt></span><span style="font-size: 15px;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                      <bdt class="block-component"></bdt>
                                    </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Administer prize draws and competitions. </span></strong><span
              data-custom-class="body_text">We may use your information to administer prize draws and competitions when
              you elect to participate in competitions.</span><span style="font-size: 15px;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          <bdt class="block-component"></bdt>
                                        </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Request Feedback.</span></strong><span data-custom-class="body_text"> We
              may use your information to request feedback and to contact you about your use of our <bdt
                class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt>.
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                              <bdt class="statement-end-if-in-editor"></bdt>
                                              <bdt class="block-component"></bdt>
                                            </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">To protect our Services.</span></strong><span
              data-custom-class="body_text"> We may use your information as part of our efforts to keep our <bdt
                class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt> safe and secure (for example, for fraud monitoring and prevention).
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                  <bdt class="block-component"></bdt>
                                                  <bdt class="block-component"></bdt>
                                                </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">To enforce our terms, conditions and policies for Business Purposes, Legal
                Reasons and Contractual.</span></strong></span></span><br><br></li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">To respond to legal requests and prevent harm. </span></strong><span
              data-custom-class="body_text">If we receive a subpoena or other legal request, we may need to inspect the
              data we hold to determine how to respond.</span><span style="font-size: 15px;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                              data-custom-class="body_text">
                                                              <bdt class="statement-end-if-in-editor"></bdt>
                                                              <bdt class="block-component"></bdt>
                                                              <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><span
          style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span
                                                  data-custom-class="body_text"><br></span></span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span> </span>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                              data-custom-class="body_text"><strong>To deliver services
                                                                to the user.</strong> We may use your information to
                                                              provide you with the requested service.<span
                                                                style="font-size: 15px;"><span
                                                                  style="color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px;"><span
                                                                      style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px;"><span
                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px;"><span
                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px;"><span
                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;"><span
                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                            style="font-size: 15px;"><span
                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                style="font-size: 15px;"><span
                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                    style="font-size: 15px;"><span
                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px;"><span
                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                            style="font-size: 15px;"><span
                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                    data-custom-class="body_text"><span
                                                                                                                      style="font-size: 15px;"><span
                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                          style="font-size: 15px;"><span
                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                              style="font-size: 15px;"><span
                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                      style="font-size: 15px;"><span
                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                              style="font-size: 15px;"><span
                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                      style="font-size: 15px;"><span
                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                                              style="font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                      style="font-size: 15px;"><span
                                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                          <bdt
                                                                                                                                                                            class="statement-end-if-in-editor">
                                                                                                                                                                          </bdt>
                                                                                                                                                                        </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
        <bdt class="block-component"></bdt><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                              data-custom-class="body_text"><strong>To respond to user
                                                                inquiries/offer support to users.</strong> We may use
                                                              your information to respond to your inquiries and solve
                                                              any potential issues you might have with the use of our
                                                              Services.<bdt class="statement-end-if-in-editor"></bdt>
                                                              </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><span
                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                              data-custom-class="body_text">
                                                              <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><br><br>
      </li>
      <li><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong><span data-custom-class="body_text">For other
              Business Purposes.</span></strong><span data-custom-class="body_text"> We may use your information for
            other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our <bdt class="block-component"></bdt>Services<bdt
              class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>, products, marketing and your experience. 
          </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                      style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                          style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                  style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                      style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                          style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                  style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                      style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                            style="font-size: 15px;"><span
                                                              style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text">We may use and store this
                                                                information in aggregated and anonymized form so that it
                                                                is not associated with individual end users and does not
                                                                include personal information. We will not use
                                                                identifiable personal information without your
                                                                consent.<span
                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                    style="font-size: 15px;"><span
                                                                      style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px;"><span
                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px;"><span
                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px;"><span
                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;"><span
                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                            style="font-size: 15px;"><span
                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                style="font-size: 15px;"><span
                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                    style="font-size: 15px;"><span
                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px;"><span
                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                            style="font-size: 15px;"><span
                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px;"><span
                                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><span
                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                            style="font-size: 15px;"><span
                                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                  <bdt
                                                                                                                                                                                    class="statement-end-if-in-editor">
                                                                                                                                                                                  </bdt>
                                                                                                                                                                                </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><span
                                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                            style="font-size: 15px;"><span
                                                              style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text"><span
                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                    style="font-size: 15px;"><span
                                                                      style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px;"><span
                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px;"><span
                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px;"><span
                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;"><span
                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                            style="font-size: 15px;"><span
                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                style="font-size: 15px;"><span
                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                    style="font-size: 15px;"><span
                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px;"><span
                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                            style="font-size: 15px;"><span
                                                                                                              style="color: rgb(89, 89, 89);"><span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px;"><span
                                                                                                                      style="color: rgb(89, 89, 89);"><span
                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                          data-custom-class="body_text">
                                                                                                                          <bdt
                                                                                                                            class="statement-end-if-in-editor">
                                                                                                                          </bdt>
                                                                                                                        </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
      </li>
      </ul>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p id="infoshare" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH
              ANYONE?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">We only share information with your consent,
              to comply with laws, to provide you with services, to protect your rights, or to fulfill business
              obligations.</span></em></span></span></p>
    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">We may process or
          share data based on the following legal basis:</span></span></div>
    <ul>
      <li><span data-custom-class="body_text"><span
            style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Consent:</strong> We may process your data if you
            have given us specific consent to use your personal information in a specific purpose.</span><br><br></span>
      </li>
      <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legitimate
              Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate
            business interests.</span><br><br></span></li>
      <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Performance
              of a Contract: </strong>Where we have entered into a contract with you, we may process your personal
            information to fulfill the terms of our contract.</span><br><br></span></li>
      <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legal
              Obligations:</strong> We may disclose your information where we are legally required to do so in order to
            comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process,
            such as in response to a court order or a subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).</span><br><br></span></li>
      <li><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><strong>Vital
              Interests:</strong> We may disclose your information where we believe it is necessary to investigate,
            prevent, or take action regarding potential violations of our policies, suspected fraud, situations
            involving potential threats to the safety of any person and illegal activities, or as evidence in litigation
            in which we are involved.</span></span></li>
    </ul>
    <p style="font-size:15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">More specifically, we may need to process your data or share your personal
            information in the following situations:</span><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></p>
    <ul>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Vendors, Consultants and Other Third-Party Service
                Providers.</span></strong><span data-custom-class="body_text"> We may share your data with third party
              vendors, service providers, contractors or agents who perform services for us or on our behalf and require
              access to such information to do that work. Examples include: payment processing, data analysis, email
              delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to
              use tracking technology on the <bdt class="block-component"></bdt>Services<bdt
                class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt>, which will enable them to collect data about how you interact with
              the <bdt class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt> over time. This information may be used to, among other things,
              analyze and track data, determine the popularity of certain content and better understand online activity.
              Unless described in this Policy, we do not share, sell, rent or trade any of your information with third
              parties for their promotional purposes. <bdt class="block-component"></bdt>We have contracts in place with
              our data processors. This means that they cannot do anything with your personal information unless we have
              instructed them to do it. They will not share your personal information with any organisation apart from
              us. They will hold it securely and retain it for the period we instruct.<span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                    <bdt class="statement-end-if-in-editor"></bdt>
                  </span></span></span>
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span></span></span></span></span><br><br></li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Business Transfers.</span></strong><span data-custom-class="body_text"> We
              may share or transfer your information in connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of our business to another company.<bdt
                class="block-component"></bdt>
              <bdt class="block-component"></bdt>
            </span></span></span></span>
        <bdt class="block-component"></bdt></span></span></span><br><br>
      </li>
      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                data-custom-class="body_text">Business Partners.</span></strong><span data-custom-class="body_text"> We
              may share your information with our business partners to offer you certain products, services or
              promotions.</span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span></span></span><span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
              <bdt class="block-component"></bdt>
            </span></span></span></span></li>
    </ul>
    <div>
      <bdt class="block-component"><span data-custom-class="body_text"></bdt></span><span
        style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span
            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                <bdt class="block-component"><span data-custom-class="heading_1"></span></bdt>
              </span></span></span></span></span>
    </div>
    <p style="font-size:15px;"><br></p>
    <div><span id="cookies" style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
              data-custom-class="heading_1">4. DO WE USE COOKIES AND OTHER TRACKING
              TECHNOLOGIES?</span></span></strong></span></div>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">We may use cookies and other tracking
              technologies to collect and store your information.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and
          pixels) to access or store information. Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Policy<bdt class="block-component"></bdt>.</span><span
          style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span
              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text">
                    <bdt class="statement-end-if-in-editor"></bdt>
                  </span></span><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                  </span></span></span></span></span></span></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="sociallogins" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">5. HOW DO WE HANDLE YOUR SOCIAL
              LOGINS?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">If you choose to register or log in to our
              services using a social media account, we may have access to certain information about
              you.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">Our <bdt class="block-component"></bdt>Services<bdt
              class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt> offer you the ability to register and login using your third party
            social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will
            receive certain profile information about you from your social media provider. The profile Information we
            receive may vary depending on the social media provider concerned, but will often include your name, e-mail
            address, friends list, profile picture as well as other information you choose to make public. <bdt
              class="block-component">
          </span></bdt></span></span></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">We will use the information we receive only for the purposes that are described
          in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span> </span>or that are otherwise made clear to you on the <bdt class="block-component"></bdt>Services
          <bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>. Please note that we do not control, and are not responsible for, other
          uses of your personal information by your third party social media provider. We recommend that you review
          their privacy policy to understand how they collect, use and share your personal information, and how you can
          set your privacy preferences on their sites and apps.
        </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
            style="color: rgb(89, 89, 89); font-size: 15px;"><span
              style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span
                  style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                      style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span>
                      <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                      <bdt class="block-component">
                    </span></bdt></span></span></span></span></span></span></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="inforetain" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">6. HOW LONG DO WE KEEP YOUR
              INFORMATION?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">We keep your information for as long as
              necessary to fulfill the purposes outlined in this <span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">
                  <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                      data-custom-class="body_text">privacy policy</span></span>
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span> </span>unless otherwise required by law.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for
            the purposes set out in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text">privacy policy</span></span>
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span>, unless a longer retention period is required or permitted by law (such as tax, accounting
            or other legal requirements). No purpose in this policy will require us keeping your personal information
            for longer than <bdt class="block-component"></bdt>
            <bdt class="question">2 years</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>.
          </span></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal
          information, we will either delete or anonymize it, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will securely store your personal
          information and isolate it from any further processing until deletion is possible.</span></span><span
        style="color: rgb(89, 89, 89);">
        <bdt class="block-component"></bdt>
      </span></p>
    <div style="line-height: 1.5;"><br></div>
    <p id="infosafe" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">7. HOW DO WE KEEP YOUR INFORMATION
              SAFE?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">We aim to protect your personal information
              through a system of organizational and technical security measures.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">We have implemented appropriate technical and organizational security measures
          designed to protect the security of any personal information we process. However, please also remember that we
          cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your
          personal information, transmission of personal information to and from our <bdt class="block-component"></bdt>
          Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt> is at your own risk. You should only access the services within a secure
          environment.
        </span></span><span style="color: rgb(89, 89, 89);">
        <bdt class="statement-end-if-in-editor"></bdt>
      </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>
        </span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="infominors" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">8. DO WE COLLECT INFORMATION FROM
              MINORS?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">We do not knowingly collect data from or
              market to children under 18 years of age.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of
          age. By using the <bdt class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>, you represent that you are at least 18 or that you are the parent or
          guardian of such a minor and consent to such minor dependent’s use of the <bdt class="block-component"></bdt>
          Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>. If we learn that personal information from users less than 18 years of
          age has been collected, we will deactivate the account and take reasonable measures to promptly delete such
          data from our records. If you become aware of any data we have collected from children under age 18, please
          contact us at <bdt class="block-component"></bdt>
          <bdt class="question">info@wizdough.com</bdt>
          <bdt class="else-block"></bdt>.
        </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
            <bdt class="statement-end-if-in-editor"></bdt>
          </span></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="privacyrights" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY
              RIGHTS?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><span data-custom-class="body_text"><em>
              <bdt class="block-component"></bdt>In some regions, such as the European Economic Area, you have rights
              that allow you greater access to and control over your personal information. <bdt
                class="statement-end-if-in-editor"></bdt>You may review, change, or terminate your account at any time.
            </em></span>
          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
        </span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">In some regions (like the European Economic Area), you have certain rights
            under applicable data protection laws. These may include the right (i) to request access and obtain a copy
            of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of
            your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal information. To make such a request, please
            use the</span></span> </span><span data-custom-class="body_text"><a data-custom-class="link"
          href="#contact"><span style="font-size: 15px;"><span style="color: rgb(48, 48, 241);">contact
              details</span></span></a></span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text"> provided below. We will consider and act upon any request in accordance with
            applicable data protection laws.</span></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">If we are relying on your consent to process your personal information, you
            have the right to withdraw your consent at any time. Please note however that this will not affect the
            lawfulness of the processing before its withdrawal.<span style="color: rgb(89, 89, 89);"><span
                style="font-size: 15px;">
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span></span></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">If you are resident in the European Economic Area and you believe we are
            unlawfully processing your personal information, you also have the right to complain to your local data
            protection supervisory authority. You can find their contact details here:</span></span> </span><span
        data-custom-class="body_text"><span style="color: rgb(48, 48, 241);"><span style="font-size: 15px;"><a
              data-custom-class="link"
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</span></span></span><span
        style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
          <bdt class="block-component"><span data-custom-class="body_text"></bdt>
          <bdt class="block-component"></bdt>
        </span></span></span></span></span></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><u><span
                data-custom-class="body_text">Cookies and similar technologies:</span></u> </strong><span
            data-custom-class="body_text">Most Web browsers are set to accept cookies by default. If you prefer, you can
            usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies
            or reject cookies, this could affect certain features or services of our <bdt class="block-component"></bdt>
            Services<bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>. To opt-out of interest-based advertising by advertisers on our <bdt
              class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>
          </span><span data-custom-class="body_text">
            <bdt class="block-component"></bdt> visit
          </span></span> </span><span style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><a
            data-custom-class="link" href="http://www.aboutads.info/choices/" rel="noopener noreferrer"
            target="_blank"><span
              style="font-size: 15px;">http://www.aboutads.info/choices/</span></a></span></span><span
        style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">. <bdt
              class="block-component"></bdt><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                        style="color: rgb(89, 89, 89);">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span></span></span></span></span></span></span><span data-custom-class="body_text">
            <bdt class="block-component">
              <bdt class="block-component"></bdt>
            </bdt>
          </span></span></span></span></span></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p id="databreach" style="font-size:15px;"><strong><span data-custom-class="heading_1" style="font-size: 19px;">10.
          DATA BREACH</span></strong></p>
    <p style="font-size: 15px;"><span data-custom-class="body_text" style="font-size: 15px;">A privacy breach occurs
        when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You
        will be notified about data breaches when <bdt class="question">Wizbord Inc.</bdt> believes you are likely to be
        at risk or serious harm. For example, a data breach may be likely to result in serious financial harm or harm to
        your mental or physical well-being. In the event that <bdt class="question">Wizbord Inc.</bdt> becomes aware of
        a security breach which has resulted or may result in unauthorized access, use or disclosure of personal
        information <bdt class="question">Wizbord Inc.</bdt> will promptly investigate the matter and notify the
        applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal
        data breach is unlikely to result in a risk to the rights and freedoms of natural persons.</span><span
        style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <bdt class="statement-end-if-in-editor">
                  <bdt class="statement-end-if-in-editor"></bdt>
                </bdt>
              </span></span></span></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p id="DNT" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
              data-custom-class="heading_1">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
            data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications
            include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to
            have data about your online browsing activities monitored and collected. No uniform technology standard for
            recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
            browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
            If a standard for online tracking is adopted that we must follow in the future, we will inform you about
            that practice in a revised version of this <span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text">
                <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text">privacy policy</span></span>
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span>.</span></span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p id="caresidents" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
              PRIVACY RIGHTS?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, if you are a resident of California, you
              are granted specific rights regarding access to your personal information.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">California Civil Code Section 1798.83, also known as the “Shine The Light”
            law, permits our users who are California residents to request and obtain from us, once a year and free of
            charge, information about categories of personal information (if any) we disclosed to third parties for
            direct marketing purposes and the names and addresses of all third parties with which we shared personal
            information in the immediately preceding calendar year. If you are a California resident and would like to
            make such a request, please submit your request in writing to us using the contact information provided
            below.</span></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered
          account with the <bdt class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>, you have the right to request removal of unwanted data that you publicly
          post on the <bdt class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>. To request removal of such data, please contact us using the contact
          information provided below, and include the email address associated with your account and a statement that
          you reside in California. We will make sure the data is not publicly displayed on the <bdt
            class="block-component"></bdt>Services<bdt class="statement-end-if-in-editor"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>, but please be aware that the data may not be completely or
          comprehensively removed from our systems.
        </span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="policyupdates" style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">13. DO WE MAKE UPDATES TO THIS
              POLICY?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span
                data-custom-class="body_text">In Short:</span></span> </em> </strong><span
          style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, we will update this policy as necessary
              to stay compliant with relevant laws.</span></em></span></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">We may update this <span style="color: rgb(89, 89, 89);"><span
              data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span> </span>from time to time. The updated version will be indicated by an updated “Revised” date and the
          updated version will be effective as soon as it is accessible. If we make material changes to this <span
            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span></span>, we may notify you either by prominently posting a notice of such changes or by directly
          sending you a notification. We encourage you to review this <span style="color: rgb(89, 89, 89);"><span
              data-custom-class="body_text">
              <bdt class="block-component"></bdt><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text">privacy policy</span></span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span> </span>frequently to be informed of how we are protecting your information.</span></span></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="contact" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
            style="font-size: 19px;"><span data-custom-class="heading_1">14. HOW CAN YOU CONTACT US ABOUT THIS
              POLICY?</span></span></strong></span></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
          data-custom-class="body_text">If you have questions or comments about this policy, you may <bdt
            class="block-component"></bdt>email us at <bdt class="question">info@wizdough.com</bdt>
          <bdt class="statement-end-if-in-editor"></bdt>.
        </span></span></p>
    </div>
    <style>
      ul {
        list-style-type: square;
      }

      ul>li>ul {
        list-style-type: circle;
      }

      ul>li>ul>li>ul {
        list-style-type: square;
      }

      ol li {
        font-family: Arial;
      }
    </style>
  </div>
  <div style="padding:16px 0 20px;background: transparent">
    <h1 style="font-family: Arial;font-size: 19px;color: #000000;text-transform: uppercase">
      How can you review, update, or delete the data we collect from you?
    </h1>
    <div style="color: #595959;font-size: 14px;font-family: Arial;margin-top:18px; line-height: 1.2">
      Based on the laws of some countries, you may have the right to request access to the personal information we
      collect from you, change that information, or delete it in some circumstances. To request to review, update, or
      delete your personal information, please submit a request form by clicking <a
        style="color: rgb(48, 48, 241) !important;"
        href="https://wizbord.com/contact" target="_blank">here</a>. We will
      respond to your request within 30 days.
    </div>
  </div>
</body>

</html>
`;